import axios from "axios";
import interceptor from "@/plugins/axios/interceptor";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': "*"
  },
});

const httpImages = axios.create({
  baseURL: process.env.VUE_APP_API_UPLOAD_IMAGE,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

interceptor.configResponse([http, httpImages])
interceptor.configRequest([http, httpImages])

export {
  http, httpImages
}
