<template>
  <a-layout class="admin-layout">
    <main-layout-sider :collapsed='collapsed' />
    <a-layout class="admin-right">
      <a-layout-header class="admin-header d-flex align-center justify-between">
        <div class='d-flex align-center'>
<!--          <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click='collapsed = !collapsed' class="menu-fold"/>-->
          <breadcrumb />
        </div>
        <div class="header-right">
          <a-dropdown :trigger="['click']" :placement="'bottomRight'" v-if="userInfo">
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <div class="d-flex align-center">
                <a-avatar class="mr-10" v-if="userInfo.avatar"
                          :src="userInfo.avatar"
                />
                <a-avatar class="mr-10" v-else
                          :src="require('@/assets/images/no-avatar.png')"
                />
                <span class="mr-10">{{ userInfo.userName || 'Admin' }}</span>
                <a-icon type="down" class="fs-11"/>
              </div>
            </a>
            <a-menu slot="overlay" class="dropdown-menu">
              <a-menu-item class="d-flex align-center" key="header_action_2" @click="onLogout">
                <a-icon type="logout"/>
                <span>Log out</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <!-- content -->
      <a-layout-content class="admin-content">
        <transition name="page" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import AuthService from '@/services/app/auth.service'

export default {
  name: "MainLayoutContainer",
  components: {
    MainLayoutSider: () => import('./MainLayoutSider'),
    Breadcrumb: () => import('@/components/Tools/Breadcrumb')
  },
  data() {
    return {
      collapsed: false,
      userInfo: null,
    };
  },
  computed: {
    // ...mapGetters('AuthModule', [
    //   'userInfo'
    // ]),
  },
  created() {
    // this.getUserInfo()  -- action verify user by token
    this._getUserInfo()
  },
  methods: {
    // ...mapActions('AuthModule', [
    //   'getUserInfo'
    // ]),

    _getUserInfo() {
      let existUser = localStorage.getItem("User-Info")
      let existToken = localStorage.getItem("Access-Token")
      if (existToken && existUser) {
        this.userInfo = JSON.parse(existUser)
      }
    },

    onLogout() {
      AuthService.logout()
      this.$notification.success({
        message: 'Log out successfully'
      })
      this.$router.replace({name: "Login"});
    }
  }
};
</script>

<style lang="scss">
.admin-layout {
  min-height: 100vh !important;

  .admin-header {
    background-image: linear-gradient(to right, #007bb4, #4b779f);
    color: #fff;
    height: 58px;
    padding: 0 16px 0 24px;
    line-height: unset;

    .menu-fold {
      margin-right: 20px;
      svg {
        font-size: 18px;
      }
    }

    i {
      cursor: pointer;
      font-size: 20px;
      margin-right: 10px;
    }

    a {
      color: #fff;
    }

    .menu-block {
      .router-wrap {
        text-transform: uppercase;

        a {
          line-height: 50px;
          padding: 0px 15px;
          display: inline-block;
          font-weight: 500;
          transition: 0.3s;

          &:hover {
            color: #d1d1d1;
          }
        }
      }
    }
  }

  .admin-content {
    background-color: #f0f2f5;
    max-height: calc(100vh - 58px);
    overflow-y: auto;
  }
}

// .ant-dropdown {
//   top: 50px !important;
// }

.page-enter-active, .page-leave-active {
  transition: opacity 0.3s, transform 0.6s;
}
.page-enter, .page-leave-to {
  opacity: 0;
  transform: translateY(-10%);
}

</style>
