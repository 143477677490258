import {http} from '@/plugins/axios/axios'

const login = (body) => {
  return http.post('/v1/login', body)
}
const logout = () => {
  return localStorage.clear();
}
const getInfo = () => {
  return http.get('/v1.0/user');
}

export default {
  login, logout, getInfo
}
