import * as mutationType from './mutation-type'
import AuthService from "@/services/app/auth.service";

const state = () => ({
  userInfo: null
})

const getters = {
  userInfo(state) {
    return state.userInfo
  }
}

const mutations = {
  [mutationType.AUTH.SET_USER_INFO](state, payload) {
    state.userInfo = payload;
  }
}

const actions = {
  async getUserInfo({commit}) {
    try {
      const result = await AuthService.getInfo();
      if (result.code === 1) {
        commit(mutationType.AUTH.SET_USER_INFO, result.data);
      }
      return result.data;
    } catch (err) {
      return err;
    }
  }
}

const AuthModule = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};

export default AuthModule
