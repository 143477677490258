import Vue from 'vue'
import Vuex from 'vuex'
import wallet from '../datas/airdrop_wallet.json'
import AuthModule from "@/store/auth";
import getWeb3 from '../helpers/getWeb3';
import PromotionController from '../contracts/PromotionController.json'
// import { multicall } from '../helpers/mutilcall';
import Web3 from 'web3';
import reportsService from '../services/app/reports.service';
import characterService from '../services/app/character.service';
const PromotionContract = {
  abi: PromotionController,
  address: {
    [56]:
      process.env.VUE_APP_PROMOTION_ADDRESS_MAINNET,
    [97]:
      process.env.VUE_APP_PROMOTION_ADDRESS_TESTNET,
  },
}
export const formatPriceNumber = price => {
  return Number(Web3.utils.fromWei(price, 'ether'));
};
Vue.use(Vuex)
let intervalProcess = null
export default new Vuex.Store({
  state: {
    breadcrumb: [],
    web3: null,
    account: null,
    instanceReport: null,
    report: null,
    percentLoading: 1,
    plantReport: [],
    userReport: {},
    currentPriceByMap: null,
    currentPriceReward: null
  },
  getters: {
    breadcrumb(state) {
      return state.breadcrumb
    },
    report(state) {
      return state.report
    },
    percentLoading (state) {
      return state.percentLoading
    },
    plantReport (state) {
      return state.plantReport
    },
    userReport (state) {
      return state.userReport
    },
    currentPriceByMap (state) {
      return state.currentPriceByMap
    },
    currentPriceReward (state) {
      return state.currentPriceReward
    }
  },
  mutations: {
    SET_BREADCRUMB(state, payload) {
      state.breadcrumb = payload
    },
    setWeb3(state, payload) {
      state.web3 = payload
    },
    setAccount(state, payload) {
      state.account = payload
    },
    setInstanceReport(state, payload) {
      state.instanceReport = payload
    },
    setPlantReport(state, payload) {
      state.plantReport = payload
    },
    setUserReport(state, payload) {
      state.userReport = payload
    },
    setReport(state, payload) {
      state.report = payload
      if (payload === null) {
        state.percentLoading = 1
      }
    },
    setPercentLoading(state, payload) {
      clearInterval(intervalProcess)
      if (payload === 5) {
        intervalProcess = setInterval(() => {
          if (state.percentLoading + 1 < 99) {
            state.percentLoading++
          } else {
            clearInterval(intervalProcess)
          }
        }, 100)
      } else {
        state.percentLoading = payload
      }
    },
    setCurentPriceByMap(state, price) {
      state.currentPriceByMap = price
    },
    setCurrentPriceReward (state, price) {
      state.currentPriceReward = price
    }
  },
  actions: {
    async setWeb3({ commit }) {
      const web3 = await getWeb3();
      const accounts = await web3.eth.getAccounts();
      // window.web3.version.getNetwork((e, netId) => {
      //   if (netId !== process.env.VUE_APP_NETWORK_ID) {
      //     alert('Unknown network, please change network to Ropsten testnet network');
      //     return;
      //   }
      // });
      if (accounts.length > 0) {
        const account = accounts[0];
        commit('setWeb3', getWeb3);
        commit('setAccount', account);
      } else {
        console.log('Account not found');
      }
    },
    async initContarct({ commit, state }) {
      const web3 = await state.web3();
      const networkId = process.env.VUE_APP_NETWORK_ID;
      const promotion = new web3.eth.Contract(PromotionContract.abi, PromotionContract.address[networkId], {
        transactionConfirmationBlocks: 1
      });
      commit('setInstanceReport', promotion);
    },
    getReport({commit, state}) {
      if (wallet) {
        let listData = []
        let listAddress = []
        let countCompleted = 0
        let intervalCall = null
        let totalConsumer = 0
        wallet.forEach((item, index) => {
          if (listAddress.indexOf(item.address) === -1) {
            listAddress.push(item.address)
            try {
              state.instanceReport.methods.getUserPromotionStatistics(item.address)
              .call({from: state.account}).then((res) => {
                listData.push(res)
                totalConsumer++
                countCompleted++
              }).catch((ex)=> {
                countCompleted++
              })
            }catch(Ex){
              countCompleted++
            }
          }
        })
        const _lengthAddress = listAddress.length
        commit('setPercentLoading', 5)
        clearInterval(intervalCall)
        intervalCall = setInterval(() => {
          // commit('setPercentLoading', Math.round((countCompleted/_lengthAddress) * 100))
          if (_lengthAddress === countCompleted) {
            commit('setPercentLoading', 100)
            saveInfo(listData)
            clearInterval(intervalCall)
          }
        }, 3000)

        const saveInfo = (listData) => {
          let totalSpending = 0
          let _totalConsumer = 0
          let reportByMap = {
            1: {
              consumer: 0,
              spending: 0,
              morphing: 0
            },
            2: {
              consumer: 0,
              spending: 0,
              morphing: 0
            },
            3: {
              consumer: 0,
              spending: 0,
              morphing: 0
            },
            4: {
              consumer: 0,
              spending: 0,
              morphing: 0
            },
            5: {
              consumer: 0,
              spending: 0,
              morphing: 0
            }
          }
          listData.forEach(element => {
            element[0].forEach((map, index) => {
              if (reportByMap[map]) {
                reportByMap[map].consumer++
                reportByMap[map].morphing += Number(element[1][index])
                reportByMap[map].spending += formatPriceNumber(element[2][index])
                totalSpending += formatPriceNumber(element[2][index])
                _totalConsumer++
              }
            });

          });
          const report = {
            totalWallet: _lengthAddress,
            totalConsumer: _totalConsumer,
            totalSpending: totalSpending,
            reportByMap: reportByMap
          }
          commit('setReport', report)
        }
      }
    },
    async getPlantReport({commit}) {
      const _report = await reportsService.getReportPlant()
      if (_report.code === 1) {
        console.log(_report.data)
        commit('setPlantReport', _report.data)
      }
    },
    async getUserReport({commit}) {
      const _report = await Promise.all([reportsService.countTier(), reportsService.rewardsByTier()])
      if (_report[0].code === 1 && _report[1].code === 1) {
        const _tiers = _report[0].data;
        const _reward = _report[1].data.detail;
        const _totalUserByTier = [];
        let _totalRef = 0
        _tiers.forEach(element => {
        let _egg;
          _reward.forEach(item => {
            if (element.tier === item.tier) {
              item.detail.forEach((_type) => {
              if (_type.type === 'BUY-EGG') {
                _egg = _type
              }
              })
            }
          });

          _totalUserByTier.push({
            ...element,
            totalOpenEgg: _egg ? _egg.total : 0
          })
          _totalRef += element.total
          // _totalOpenEgg += _egg ? _egg.total : 0
        });

        // _reward.forEach(item => {
        //   item.detail.forEach((_type) => {
        //   if (_type.type === 'BUY-EGG') {
        //     _totalRwEgg += parseFloat(_type.totalRewards)
        //   }
        //   if (_type.type === 'USER-FIGHT') {
        //     _totalRwCombat += parseFloat(_type.totalRewards)
        //   }
        //   })
        // });

        commit('setUserReport', {
          totalRef: _totalRef,
          totalOpenEgg: _report[1].data.totalOpenEgg,
          totalRefRw: _report[1].data.totalRefRewards,
          totalRwEgg: _report[1].data.totalEggRewards,
          totalRwCombat: _report[1].data.totalCombatRewards,
          totalUserByTier: _totalUserByTier,
          totalRwList: _reward
        })

      }
    },
    async getPrice({commit}, map) {
      const _Rs = await characterService.getPriceByMap(map)
      if (_Rs.code === 1) {
        commit('setCurentPriceByMap', _Rs.data)
      }
    },
    async setPriceByMap(_, params) {
      const _Rs = await characterService.setPriceByMap(params)
      return _Rs
    },
    async getReward({commit}, data) {
      const _Rs = await characterService.getMaxReward(data.map, data.rank)
      if (_Rs.code === 1) {
        commit('setCurrentPriceReward', _Rs.data)
      }
    },
    async setRw(_, params) {
      const _Rs = await characterService.setMaxReward(params)
      return _Rs
    },
  },
  modules: {
    AuthModule
  }
})
