import {http} from '@/plugins/axios/axios'

const getList = (payload) => {
  return http.post('/v1/report', payload)
}

const exportList = (payload, params) => {
  return http.post('/v1/report/export', payload, {
    params,
    responseType: 'blob'
  })
}
const getReportPlant = () => {
  return http.get('/v1/referral-campaign/statistics/reward-by-map?campaign_id=1')
}

const countTier = () => {
  return http.get('/v1/referral-campaign/statistics/count-tier?campaign_id=1')
}

const rewardsByTier = () => {
  return http.get('/v1/referral-campaign/statistics/rewards-by-tier?campaign_id=1')
}



export default {
  getList, exportList,
  getReportPlant, countTier, rewardsByTier
}
