import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AdminLayout from "@/layout/MainLayout/MainLayoutContainer";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AdminLayout,
    redirect: '/users',
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/Dashboard/index.vue"),
        meta: {
          requireAuth: true,
          roles: ['SUPER_ADMIN']
        },
      },
      {
        path: "users",
        name: "UsersManagement",
        component: () => import("@/views/Users/index.vue"),
        meta: {
          requireAuth: true,
          roles: ['SUPER_ADMIN']
        },
      },
      // {
      //   path: "create-pool",
      //   name: "CreatePoolView",
      //   component: () => import("@/views/CreatePool/index.vue"),
      //   meta: {
      //     requireAuth: true,
      //     roles: ['SUPER_ADMIN']
      //   },
      // },
      {
        path: "create-pool/:id",
        name: "CreatePoolList2",
        component: () => import("@/views/CreatePool/CreatePoolList2.vue"),
        meta: {
          requireAuth: true,
          roles: ['SUPER_ADMIN']
        },
      },
      {
        path: "edit-pool",
        name: "EditPoolView",
        component: () => import("@/views/EditPool/index.vue"),
        meta: {
          requireAuth: true,
          roles: ['SUPER_ADMIN']
        },
      },
      {
        path: "game-action",
        name: "GameActionView",
        component: () => import("@/views/GameAction/index.vue"),
        meta: {
          requireAuth: true,
          roles: ['SUPER_ADMIN']
        },
      },
      {
        path: "jackpot",
        name: "JackpotList",
        component: () => import("@/views/Jackpot/index.vue"),
        meta: {
          requireAuth: true,
          roles: ['SUPER_ADMIN']
        },
      },
      {
        path: "wallets/:id?",
        name: "WalletDetail",
        component: () => import("@/views/Wallets/WalletDetail.vue"),
        meta: {
          requireAuth: true,
          roles: ['SUPER_ADMIN']
        },
      },
      {
        path: "system-config",
        name: "SystemConfigs",
        component: () => import("@/views/SystemConfigs"),
        meta: {
          requireAuth: true,
          roles: []
        },
      },
      {
        path: "account",
        name: "Users",
        component: () => import("@/views/Users"),
        meta: {
          requireAuth: true,
          roles: []
        },
      },
      {
        path: "report",
        name: "Reports",
        component: () => import("@/views/Reports"),
        meta: {
          requireAuth: true,
          roles: []
        },
      },
      {
        path: "plant-report",
        name: "PlantRefReport",
        component: () => import("@/views/PlantRefReport"),
        meta: {
          requireAuth: true,
          roles: ['SUPER_ADMIN']
        },
      },
      {
        path: "user-report",
        name: "UserRefReport",
        component: () => import("@/views/UserRefReport"),
        meta: {
          requireAuth: true,
          roles: []
        },
      },
      {
        path: "token-report",
        name: "TokenReport",
        component: () => import("@/views/TokenReport"),
        meta: {
          requireAuth: true,
          roles: []
        },
      },
      {
        path: "update-price",
        name: "UpdatePriceNFT",
        component: () => import("@/views/UpdatePriceNFT"),
        meta: {
          requireAuth: true,
          roles: []
        },
      },
      {
        path: "update-reward",
        name: "UpdateReward",
        component: () => import("@/views/UpdateReward"),
        meta: {
          requireAuth: true,
          roles: []
        },
      }
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Auth/AuthLogin"),
    meta: {
      requireAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// const commonRouter = ['TokenReport', 'UpdatePriceNFT','UpdateReward']

router.beforeEach((to, from, next) => {
  let accessToken = localStorage.getItem('Access-Token')
  let accountRoles = localStorage.getItem('User-Info') ? JSON.parse(localStorage.getItem('User-Info')).roles : []
  if (to.meta.requireAuth && !accessToken) {next({ name: "Login" })}
  else if (to.name === "Login" && accessToken) {
    next({ name: "UsersManagement" });
  } else if (accountRoles && accountRoles.length && accountRoles.some(role => to.meta.roles.includes(role))) {
    next();
  } else {
    next()
  }
});

export default router;
