import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'ant-design-vue/dist/antd.css';
import Antd from "ant-design-vue";
import {BreadcrumbMixin} from "@/helpers/mixin";
import moment from 'moment';

Vue.config.productionTip = false;
Vue.prototype.$notification = Antd.notification
Vue.use(Antd)

Vue.mixin(BreadcrumbMixin)

Vue.directive('click-outside', {
  bind () {
      this.event = event => this.vm.$emit(this.expression, event)
      this.el.addEventListener('click', this.stopProp)
      document.body.addEventListener('click', this.event)
  },
  unbind() {
    this.el.removeEventListener('click', this.stopProp)
    document.body.removeEventListener('click', this.event)
  },

  stopProp(event) { event.stopPropagation() }
})

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('hh:mm:ss MM/DD/YYYY')
  }
});

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
