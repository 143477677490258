import {http} from '@/plugins/axios/axios'

const getPriceByMap = (map) => {
  return http.get(`/v1/contract/characters/price?map=${map}`)
}

const setPriceByMap = (payload) => {
    ///payload: {
      // map: 3,
      // price: 111
    // }
    return http.put('/v1/contract/characters/price', payload)
}

const getMaxReward = (map, rank) => {
  return http.get(`/v1/pet/max-reward?map=${map}&rank=${rank}`)
}

const setMaxReward = (data) => {
  // {
  //   "map": 0,
  //   "rank": 0,
  //   "maxReward": 0
  // }
    return http.put('/v1/pet/max-reward', data)
}

export default {
  getPriceByMap, setPriceByMap, getMaxReward, setMaxReward
}
