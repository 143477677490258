<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@import "assets/styles/main";
@import "assets/styles/custom-antd";
</style>
